.main-modal {
  z-index: 10;
  background: #00000080;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.show-form-modal {
  display: flex;
}

.modal-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  display: flex;
  position: relative;
}

.modal__close-btn {
  cursor: pointer;
  background: #fff;
  border-radius: 50px;
  justify-content: center;
  align-self: flex-end;
  align-items: center;
  width: 40px;
  height: 40px;
  transition: background .3s;
  display: flex;
}

.modal__close-btn:hover {
  background: var(--basic-brand);
}

.modal__text {
  text-align: center;
  background-color: #fff;
  border-radius: 40px;
  padding: 32px;
  font-size: 20px;
  font-weight: 500;
  box-shadow: 0 4px 8px #0000001a;
}

.modal__text--color {
  color: var(--basic-brand);
  text-shadow: 0 0 5px #1b1b1b1a;
}

.mortgage {
  background: #fff;
  border-radius: 40px;
  width: 100%;
  height: auto;
  margin-bottom: 80px;
  padding: 32px;
}

.mortgage-form-head__title {
  cursor: var(--basic-black);
  margin: 0;
  font-size: 32px;
  font-weight: 700;
}

.mortgage-form-head__tegs {
  -webkit-user-select: none;
  user-select: none;
  flex-wrap: nowrap;
  gap: 8px;
  margin-top: 16px;
  display: flex;
}

.mortgage-form-head__teg {
  display: inline-block;
}

.mortgage-form-head__teg-input {
  display: none;
}

.mortgage-form-head__teg-name {
  background: #eff1f7;
  border-radius: 100px;
  padding: 8px 24px;
  font-size: 13px;
  font-weight: 600;
  display: inline-block;
}

.mortgage-form-head__teg-input:checked + .mortgage-form-head__teg-name {
  background: var(--basic-brand);
}

.mortgage-form-head__tegs-option {
  color: var(--basic-black);
  background: #eff1f7;
  border-radius: 33px;
  padding: 8px 24px;
  font-size: 13px;
  font-weight: 600;
}

.mortgage-form {
  flex-direction: column;
  gap: 40px;
  margin-top: 40px;
  display: flex;
}

.mortgage-form__params {
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  display: grid;
}

.mortgage-form__params-inputs {
  flex-direction: column;
  gap: 8px;
  display: flex;
}

.modal-content__inputs-last {
  display: none;
}

.mortgage-form__params-label {
  color: var(--basic-black);
  font-size: 13px;
  font-weight: 500;
}

.mortgage-form__params-input {
  box-sizing: border-box;
  color: var(--basic-black);
  background: #eff1f7;
  border: none;
  border-radius: 16px;
  outline: none;
  width: 100%;
  height: 52px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 16px;
  font-weight: 700;
}

.mortgage-form__params-input::placeholder {
  color: #0003;
  font-size: 16px;
  font-weight: 600;
}

.mortgage-form-contacts {
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  gap: 24px;
  display: flex;
}

.mortgage-form-contacts__info {
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  width: 100%;
  max-width: 866px;
  display: grid;
}

.mortgage-form-contacts__button {
  background: var(--basic-brand);
  color: var(--basic-black);
  border: none;
  border-radius: 60px;
  width: 406px;
  height: 52px;
  font-size: 16px;
  font-weight: 700;
}

.feedback {
  background: #fff;
  border-radius: 40px;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  width: 100%;
  height: auto;
  margin-bottom: 80px;
  padding: 32px;
  display: grid;
  position: relative;
}

.feedback-form {
  flex-direction: column;
  gap: 32px;
  width: 100%;
  display: flex;
}

.feedback-form__title {
  width: 65%;
  margin: 0;
  font-size: 32px;
  font-weight: 700;
}

.feedback-form__inputs {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  display: flex;
}

.feedback-form__input {
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  width: 100%;
  display: grid;
}

.feedback-form__input-description {
  margin: 0;
}

.feedback-form__input-info {
  flex-direction: column;
  gap: 8px;
  display: flex;
}

.feedback-form__input-label {
  color: var(--basic-black);
  font-size: 13px;
  font-weight: 500;
}

.feedback-form__input-el {
  box-sizing: border-box;
  color: var(--basic-black);
  background: #eff1f7;
  border: none;
  border-radius: 16px;
  outline: none;
  width: 100%;
  height: 52px;
  padding-left: 16px;
  font-size: 16px;
  font-weight: 700;
}

.feedback-form__input-el::placeholder {
  color: #0003;
  font-size: 16px;
  font-weight: 600;
}

.feedback-form__input-button {
  background: var(--basic-brand);
  color: var(--basic-black);
  border: none;
  border-radius: 60px;
  width: 100%;
  height: 52px;
  font-size: 16px;
  font-weight: 700;
}

.feedback-form__input-description {
  color: #00000080;
  text-align: center;
  width: 80%;
  font-size: 13px;
  font-weight: 500;
}

.feedback-form__input-description--link {
  color: #00000080;
  transition: color .3s;
}

.feedback-form__input-description--link:hover {
  color: var(--basic-brand);
}

.feedback-img {
  height: 366px;
  position: absolute;
  bottom: 0;
  right: 15%;
}

.feedback-img__picture {
  object-fit: contain;
  background-size: cover;
  width: 100%;
  height: 100%;
}

@media (width <= 1100px) {
  .mortgage-form {
    gap: 24px;
    margin-top: 24px;
  }

  .feedback {
    padding: 24px;
  }

  .feedback-form__title {
    width: 100%;
  }

  .feedback-form__input {
    grid-template-columns: 1fr;
  }

  .feedback-img {
    height: 90%;
    right: 5%;
  }

  .mortgage-form-head__tegs {
    padding-bottom: 16px;
  }
}

@media (width <= 850px) {
  .feedback-img {
    height: 90%;
    right: 2%;
  }
}

@media (width <= 750px) {
  .feedback {
    grid-template-columns: 1fr;
  }

  .feedback-img {
    display: none;
  }
}

@media (width <= 801px) {
  .mortgage-form__params {
    grid-template-columns: 1fr;
  }

  .mortgage-form-contacts {
    flex-direction: column;
  }

  .mortgage-form-contacts__button {
    width: 100%;
  }

  .mortgage-form {
    gap: 16px;
    margin-top: 16px;
  }

  .mortgage-form-head__title, .feedback-form__title {
    font-size: 24px;
  }

  .feedback-form__input-description {
    width: 100%;
  }
}

@media (width <= 601px) {
  .mortgage, .feedback {
    margin-bottom: 64px;
  }
}

@media (width <= 501px) {
  .mortgage-form-contacts__info {
    grid-template-columns: 1fr;
  }

  .mortgage {
    padding: 24px;
  }

  .mortgage-form-head__tegs {
    flex-wrap: wrap;
  }
}
/*# sourceMappingURL=index.60671332.css.map */
