/*Модальное окно*/
.main-modal {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    align-items: center;
    justify-content: center;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.show-form-modal {
    display: flex;
}

.modal-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.modal__close-btn {
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    border-radius: 50px;
    background: #ffffff;
    transition: background 0.3s;
}

.modal__close-btn:hover {
    background: var(--basic-brand);
}

.modal__text {
    padding: 32px;
    border-radius: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    background-color: #ffffff;
}

.modal__text--color {
    color: var(--basic-brand);
    text-shadow: 0 0 5px rgba(27, 27, 27, 0.1);
}

/* Расчет ипотеки*/
.mortgage {
    width: 100%;
    padding: 32px;
    background: #ffffff;
    border-radius: 40px;
    height: auto;
    margin-bottom: 80px;
}

.mortgage-form-head__title {
    margin: 0;
    font-weight: 700;
    font-size: 32px;
    cursor: var(--basic-black);
}

.mortgage-form-head__tegs {
    margin-top: 16px;
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
    user-select: none;
}

.mortgage-form-head__teg {
    display: inline-block;
}

.mortgage-form-head__teg-input {
    display: none;
}

.mortgage-form-head__teg-name {
    padding: 8px 24px;
    background: #eff1f7;
    border-radius: 100px;
    display: inline-block;
    font-size: 13px;
    font-weight: 600;
}

.mortgage-form-head__teg-input:checked + .mortgage-form-head__teg-name {
    background: var(--basic-brand);
}

.mortgage-form-head__tegs-option {
    padding: 8px 24px;
    color: var(--basic-black);
    font-size: 13px;
    font-weight: 600;
    background: #eff1f7;
    border-radius: 33px;
}

.mortgage-form {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 40px;
}

.mortgage-form__params {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
}

.mortgage-form__params-inputs {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.modal-content__inputs-last {
    display: none;
}

.mortgage-form__params-label {
    font-size: 13px;
    font-weight: 500;
    color: var(--basic-black);
}

.mortgage-form__params-input {
    box-sizing: border-box;
    width: 100%;
    height: 52px;
    padding-left: 16px;
    padding-right: 16px;
    background: #eff1f7;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 700;
    color: var(--basic-black);
    border: none;
    outline: none;
}

.mortgage-form__params-input::placeholder {
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.2);
}

.mortgage-form-contacts {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
    gap: 24px;
}

.mortgage-form-contacts__info {
    max-width: 866px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
}

.mortgage-form-contacts__button {
    width: 406px;
    height: 52px;
    border: none;
    background: var(--basic-brand);
    border-radius: 60px;
    font-size: 16px;
    font-weight: 700;
    color: var(--basic-black);
}

/* Форма обратной связи*/
.feedback {
    position: relative;
    width: 100%;
    padding: 32px;
    background: #ffffff;
    border-radius: 40px;
    height: auto;
    margin-bottom: 80px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
}

.feedback-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.feedback-form__title {
    margin: 0;
    width: 65%;
    font-size: 32px;
    font-weight: 700;
}

.feedback-form__inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.feedback-form__input {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
}

.feedback-form__input-description {
    margin: 0;
}

.feedback-form__input-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.feedback-form__input-label {
    font-size: 13px;
    font-weight: 500;
    color: var(--basic-black);
}

.feedback-form__input-el {
    box-sizing: border-box;
    width: 100%;
    height: 52px;
    padding-left: 16px;
    background: #eff1f7;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 700;
    color: var(--basic-black);
    border: none;
    outline: none;
}

.feedback-form__input-el::placeholder {
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.2);
}

.feedback-form__input-button {
    width: 100%;
    height: 52px;
    border: none;
    background: var(--basic-brand);
    border-radius: 60px;
    font-size: 16px;
    font-weight: 700;
    color: var(--basic-black);
}

.feedback-form__input-description {
    width: 80%;
    font-size: 13px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
}

.feedback-form__input-description--link {
    color: rgba(0, 0, 0, 0.5);
    transition: color 0.3s;
}

.feedback-form__input-description--link:hover {
    color: var(--basic-brand);
}

.feedback-img {
    position: absolute;
    bottom: 0;
    right: 15%;
    height: 366px;
}

.feedback-img__picture {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-size: cover;
}

@media (max-width: 1100px) {
    .mortgage-form {
        gap: 24px;
        margin-top: 24px;
    }

    .feedback {
        padding: 24px;
    }

    .feedback-form__title {
        width: 100%;
    }

    .feedback-form__input {
        grid-template-columns: 1fr;
    }

    .feedback-img {
        height: 90%;
        right: 5%;
    }

    .mortgage-form-head__tegs {
        padding-bottom: 16px;
    }
}

@media (max-width: 850px) {
    .feedback-img {
        height: 90%;
        right: 2%;
    }
}

@media (max-width: 750px) {
    .feedback {
        grid-template-columns: 1fr;
    }

    .feedback-img {
        display: none;
    }
}

@media (max-width: 801px) {
    .mortgage-form__params {
        grid-template-columns: 1fr;
    }

    .mortgage-form-contacts {
        flex-direction: column;
    }

    .mortgage-form-contacts__button {
        width: 100%;
    }

    .mortgage-form {
        gap: 16px;
        margin-top: 16px;
    }

    .mortgage-form-head__title {
        font-size: 24px;
    }

    .feedback-form__title {
        font-size: 24px;
    }

    .feedback-form__input-description {
        width: 100%;
    }
}

@media (max-width: 601px) {
    .mortgage {
        margin-bottom: 64px;
    }

    .feedback {
        margin-bottom: 64px;
    }
}

@media (max-width: 501px) {
    .mortgage-form-contacts__info {
        grid-template-columns: 1fr;
    }

    .mortgage {
        padding: 24px;
    }

    .mortgage-form-head__tegs {
        flex-wrap: wrap;
    }
}
